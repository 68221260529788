/* Application-wide Styles */
html,
body,
#root {
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

/* body {
    margin: 2rem;
} */

h1 {
  color: #369;
  font-size: 250%;
}

h2,
h3 {
  color: #444;
  font-weight: lighter;
}

input,
button,
code {
  color: #333;
  font-family: "Ubuntu Mono", sans-serif;
}

code {
  font-size: 90%;
  padding: 0;
  margin: 0;
  border: 0;
  color: #EEE;
}

code pre {
  background-color: #444;
  padding: 1rem;
  margin: 0;
  border: 0;
}

a {
  text-decoration: none;
  color: #0074D9;
  position: relative;
}

a::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #0074D9;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (hover: hover) and (pointer: fine) {
  a:hover::before {
    left: 0;
    right: auto;
    width: 100%;
  }
}

.nav-item .nav-link {
  text-decoration: none;
  position: relative;
}

.nav-item .nav-link:hover {
  color: #fff;
}

.nav-item .nav-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.navbar-brand {
  text-decoration: none;
  position: relative;
}

.navbar-brand:hover {
  color: #fff;
}

.navbar-brand::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #fff;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.footer .nav-item .nav-link {
  text-decoration: none;
  position: relative;
}

.footer .nav-item .nav-link::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #6c757d;
  transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (hover: hover) and (pointer: fine) {
  .nav-item .nav-link:hover::before {
    left: 0;
    right: auto;
    width: 100%;
  }
}

/* everywhere else */
* {
  font-family: "Ubuntu", sans-serif;
}

.ex-divider {
  position: relative;
  height: 2rem;
}

.trans-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: linear-gradient(transparent, transparent, white);
}

.editorial {
  display: block;
  width: 100%;
  height: 100%;
  /* max-height: 2rem; */
  margin: 0;
  position: relative;
  float: left;
}

.parallax1>use {
  animation: move-forever1 10s linear infinite;
}

.parallax2>use {
  animation: move-forever2 8s linear infinite;
}

.parallax3>use {
  animation: move-forever3 6s linear infinite;
}

.parallax4>use {
  animation: move-forever4 4s linear infinite;
}

.delay1 {
  animation-delay: -1s !important;
}

.delay2 {
  animation-delay: -2s !important;
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(85px, 0%);
  }

  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever4 {
  0% {
    transform: translate(-90px, 0%);
  }

  100% {
    transform: translate(85px, 0%);
  }
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}